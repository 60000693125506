import exceptionHandler from '../../utils/exceptionHandler.platform';
import { convertCanonicalPositionToChunkAware } from '../utils/locationSerialization/chunked';
// eslint-disable-next-line import/no-cycle
import { forceContentLoadForChunk, getChunkContainerStatus, getChunkIndexToChunkIdMap } from './chunkedContent';

let contentRoot: HTMLElement | null = null;
let chunkIdToHighlightsMap: { [chunkId: string]: Set<string>; } = {};
let highlightIdToChunksMap: { [highlightId: string]: Set<string>; } = {};
// needs to be populated lazily after chunked content init
let chunkIndexToChunkIdMap: { [chunkIndex: string]: string; } | null = null;

/**
 * We need to treat highlight rendering differently for chunked documents. Specifically, we want to
 * 1. Render a highlight when all the chunks it's in are loaded.
 * 2. Unrender a highlight when one of its chunks are unloaded.
 *
 * To achieve this, we subscribe to chunk events, and for each highlight we keep track of which chunks they belong to.
 * We also disable regular 'progressive' rendering of highlights which may interfere with the highlight management we do here.
 *
 */

export function trackHighlightForChunking(highlightId: string, location: string) {
  // needs to be called for each highlight added so that we can correctly respond to 'chunk un/loaded' events.
  // idempotent, so no harm in calling multiple times for the same highlight ID & location.
  const prevContainingChunkIds = highlightIdToChunksMap[highlightId] ?? new Set();
  const containingChunkIds = findChunkIdsContainingSerializedSelection(location);
  for (const chunkId of containingChunkIds.values()) {
    chunkIdToHighlightsMap[chunkId] ??= new Set();
    chunkIdToHighlightsMap[chunkId].add(highlightId);
  }
  const chunkIdsToRemove = Array.from(prevContainingChunkIds).filter((id) => !containingChunkIds.has(id));
  for (const chunkId of chunkIdsToRemove) {
    if (!chunkIdToHighlightsMap[chunkId]) {
      exceptionHandler.captureException('chunk has no cached highlight set', {
        extra: {
          chunkId,
          highlightId,
          location,
          chunkIdToHighlightsMap,
          chunkIdsToRemove,
          containingChunkIds,
        },
      });
      continue;
    }
    chunkIdToHighlightsMap[chunkId].delete(highlightId);
  }
  highlightIdToChunksMap[highlightId] = containingChunkIds;
}


export function isHighlightFullyInLoadedChunks(highlightId: string): boolean {
  const chunkIds = highlightIdToChunksMap[highlightId] ?? new Set();
  const statuses = Array.from(chunkIds, getChunkContainerStatus);
  return statuses.every((status) => status === 'loaded');
}

export function getHighlightsToRenderOnChunkLoad(chunkId: string): string[] {
  // called on chunk content load.
  const highlightIds = chunkIdToHighlightsMap[chunkId] ?? new Set();
  return Array.from(highlightIds).filter(isHighlightFullyInLoadedChunks);
}

export function getHighlightsToUnrenderOnChunkUnload(chunkId: string): string[] {
  // called on chunk content unload.
  const highlightIds = chunkIdToHighlightsMap[chunkId] ?? new Set();
  return Array.from(highlightIds).filter((id) => !isHighlightFullyInLoadedChunks(id));
}

function findChunkIdsContainingSerializedSelection(serializedSelection: string): Set<string> {
  if (!contentRoot) {
    exceptionHandler.captureException('no content root for finding chunk IDs for highlight', {
      extra: {
        serializedSelection,
      },
    });
    return new Set();
  }
  let [firstChunkIndex, lastChunkIndex] = [Number.MAX_SAFE_INTEGER, -1]; // inclusive range
  for (const serializedRange of serializedSelection.split('|')) {
    for (const position of serializedRange.split(',')) {
      const chunkAware = convertCanonicalPositionToChunkAware(position, contentRoot);
      if (!chunkAware) {
        exceptionHandler.captureException('Could not convert position to chunk aware', {
          extra: {
            position,
            firstChunkIndex,
            lastChunkIndex,
            serializedSelection,
          },
        });
        continue;
      }
      if (chunkAware.chunkIndex < firstChunkIndex) {
        firstChunkIndex = chunkAware.chunkIndex;
      } else if (chunkAware.chunkIndex > lastChunkIndex) {
        lastChunkIndex = chunkAware.chunkIndex;
      }
    }
  }
  if (!chunkIndexToChunkIdMap) {
    chunkIndexToChunkIdMap = getChunkIndexToChunkIdMap();
  }
  // grab all chunk IDs between the first and last chunk index that this selection is in.
  // this to avoid rendering a highlight spanning three or more chunks when middle chunks aren't loaded yet.
  const chunkIds = new Set<string>();
  for (let index = firstChunkIndex; index <= lastChunkIndex; index++) {
    const chunkId = chunkIndexToChunkIdMap[index.toString()];
    if (!chunkId) {
      exceptionHandler.captureException('chunk with index has no corresponding chunk ID', {
        extra: {
          index,
          firstChunkIndex,
          lastChunkIndex,
          serializedSelection,
        },
      });
      continue;
    }
    chunkIds.add(chunkId);
  }
  return chunkIds;
}

export function initHighlightTrackingForChunking(containerNode: HTMLElement) {
  chunkIdToHighlightsMap = {};
  highlightIdToChunksMap = {};
  chunkIndexToChunkIdMap = null;
  contentRoot = containerNode;
}

export async function forceChunkContentLoadsForHighlight(highlightId: string): Promise<void> {
  const chunkIds = highlightIdToChunksMap[highlightId] ?? new Set();
  const promises = Array.from(chunkIds).map(forceContentLoadForChunk);
  await Promise.all(promises);
}
