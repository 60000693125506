import type { FirstClassDocument } from '../types';
import checkIfDocumentIsInPaginatedMode from './checkIfDocumentIsInPaginatedMode';
import { globalState } from './models';
import { getDocument } from './stateGetters';

// Don't show the annotation popover when auto-highlighting or double-tapping in paged mode
export default async function checkIfWeShouldSetFocusedHighlightIdWhenCreatingAHighlight(
  userInteraction: string,
): Promise<boolean> {
  if (!['double-tap', 'selectionchange'].includes(userInteraction)) {
    return true;
  }

  const zustandState = globalState.getState();
  const openDocumentId = zustandState.openDocumentId || zustandState.focusedDocumentId;
  if (!openDocumentId) {
    return false;
  }
  return !checkIfDocumentIsInPaginatedMode(await getDocument<FirstClassDocument>(openDocumentId));
}
