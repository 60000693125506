import { DisplayTheme } from '../../../../types';
import { getInverseTheme, Spacing, Theme } from '../../../css/baseStyles';

const headerGradientLight = `linear-gradient(0deg, rgb(96.863% 98.039% 98.431%) 0%, rgb(97.243% 98.277% 98.621% / 0.87890625) 6.25%, rgb(97.598% 98.499% 98.799% / 0.765625) 12.5%, rgb(97.929% 98.706% 98.964% / 0.66015625) 18.75%, rgb(98.235% 98.897% 99.118% / 0.5625) 25%, rgb(98.517% 99.073% 99.259% / 0.47265625) 31.25%, rgb(98.775% 99.234% 99.387% / 0.390625) 37.5%, rgb(99.007% 99.38% 99.504% / 0.31640625) 43.75%, rgb(99.216% 99.51% 99.608% / 0.25) 50%, rgb(99.4% 99.625% 99.7% / 0.19140625) 56.25%, rgb(99.559% 99.724% 99.779% / 0.140625) 62.5%, rgb(99.694% 99.809% 99.847% / 0.09765625) 68.75%, rgb(99.804% 99.877% 99.902% / 0.0625) 75%, rgb(99.89% 99.931% 99.945% / 0.03515625) 81.25%, rgb(99.951% 99.969% 99.975% / 0.015625) 87.5%, rgb(99.988% 99.992% 99.994% / 0.00390625) 93.75%, rgb(100% 100% 100% / 0) 100% )`;
const headerGradientDark = `linear-gradient(to top, rgb(0, 0, 0) 14.29%, rgba(0, 0, 0, 0.87890625) 19.75%, rgba(0, 0, 0, 0.765625) 25.63%, rgba(0, 0, 0, 0.66015625) 31.09%, rgba(0, 0, 0, 0.5625) 34.45%, rgba(0, 0, 0, 0.47265625) 39.5%, rgba(0, 0, 0, 0.390625) 43.28%, rgba(0, 0, 0, 0.31640625) 46.22%, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.19140625), rgba(0, 0, 0, 0.140625), rgba(0, 0, 0, 0.09765625), rgba(0, 0, 0, 0.0625), rgba(0, 0, 0, 0.03515625), rgba(0, 0, 0, 0.015625), rgba(0, 0, 0, 0.00390625), rgba(0, 0, 0, 0))`;

// Function to generate the blur filter over the header image
export const generateImageFilterCSS = (saturationValue: number, opacityValue: number) => {
  return `blur(40px) saturate(${saturationValue}) opacity(${opacityValue})`;
};

export const generateHeaderImageFilterValues = (
  theme: Theme,
  brightness?: number,
  saturation?: number,
  lightness?: number,
) => {
  const isDarkMode = theme.type === DisplayTheme.Dark;

  if (!brightness || !saturation || !lightness) {
    const saturationValue = isDarkMode ? 1 : 1;
    const opacityValue = isDarkMode ? 0.5 : 0.3;
    return { saturation: saturationValue, opacity: opacityValue };
  }
  let saturationValue: number;
  let opacityValue: number;
  if (saturation <= 0.1) {
    saturationValue = isDarkMode ? 1.3 : 3;
  } else if (saturation < 0.4) {
    saturationValue = isDarkMode ? 1.2 : 2.3;
  } else if (saturation < 0.63) {
    saturationValue = isDarkMode ? 1.1 : 1.7;
  } else {
    saturationValue = isDarkMode ? 1 : 1;
  }

  if (brightness <= 0.1) {
    opacityValue = isDarkMode ? 0.6 : 0.4;
  } else if (brightness < 0.4) {
    opacityValue = isDarkMode ? 0.6 : 0.3;
  } else if (brightness < 0.63) {
    opacityValue = isDarkMode ? 0.5 : 0.25;
  } else {
    opacityValue = isDarkMode ? 0.4 : 0.2;
  }

  if (theme.type !== DisplayTheme.Dark) {
    if (lightness <= 0.3) {
      opacityValue += 0.2;
      saturationValue += 0.4;
    } else if (lightness > 0.8) {
      opacityValue = 0.4;
    }
  }
  if (isDarkMode) {
    if (lightness <= 0.2) {
      opacityValue += 0.3;
      saturationValue += 0.3;
    }
    // lightness modifier adding to opacity
    if (lightness <= 0.4) {
      opacityValue += 0.1;
      saturationValue += 0.3;
    }
  }

  const cappedOpacityValue = Math.min(0.6, opacityValue);

  return { saturation: saturationValue, opacity: cappedOpacityValue };
};

type HeaderStyleProps = {
  theme: Theme;
  isYoutubeVideo: boolean;
  marginTop: number;
};
export const getHeaderStyles = ({ theme, isYoutubeVideo, marginTop }: HeaderStyleProps) => {
  return `
  <style id="RWHeaderStyles">
    #document-header .tags-container {
      display: flex;
      flex-wrap: wrap;
    }
    #document-header h1 {
      overflow: hidden;
      padding-bottom: 10px;
    }

    .image-background {
      display: ${isYoutubeVideo ? 'none' : 'block'};
      top: -50px;
      position: fixed;
      height: ${marginTop + 150}px;
      width: 200%;
      z-index: -100;
      filter: ${generateImageFilterCSS(1, 0.3)};
      left: -50%;
      transition: opacity 1.2s ease;
      transform: scale(1);
    }

    .image-background img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }


    .header-container {
      position: relative;
      background: ${theme.type === DisplayTheme.Dark ? headerGradientDark : headerGradientLight};
    }

    .header {
      width: 100%;
      margin: auto;
      padding-top: ${isYoutubeVideo ? 0 : '32px'};
      max-width: ${Spacing.maxDocumentWidth}px;
    }

    .document-header-metadata-first-row {
      display: flex;
      justify-content: space-between;
    }

    .document-header-author-content {
      overflow: hidden;
      display: flex;
      align-items: center;
    }

    .header-top-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .header-secondary-image-container {
      margin: auto;
      margin-top: 10px;
      padding-bottom: 10px;
      border-radius: .25rem;
      display: block;
      max-width: 560px !important;
      min-height: 140px;
      max-height: 300px;
      overflow: hidden;
      opacity: 1;
      transition: opacity 0.2s ease, height 0.3s ease;
    }

    .header-secondary-image-container img {
      border-radius: 0.25rem;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 45%;
    }

    .tag {
      background-color: ${theme.uAlpha200};
      color: ${theme.text};
      border-radius: 4px;
      font-size: 12px;
      line-height: 20px;
      font-weight: 500;
      margin-right: 4px;
      padding-left: 6px;
      padding-right: 6px;
      margin-bottom: 8px;
    }

    .tts-play-button {
      color: ${theme.secondaryText};
      border: none;
      background: ${theme.uAlpha200};
      font-size: 13px;
      line-height: 20px;
      padding: 6px 12px 6px 9px;
      margin: -6px 2px;
      display: flex;
      align-items: center;
      border-radius: 56px;
      text-align: center;
      font-weight: 500;
    }

    .tts-play-button svg {
      margin-right: 2px;
    }
    .tts-play-button svg > path {
      fill: ${theme.text};
      color: ${theme.text};
    }

    .tts-play-button svg > rect {
      fill: ${getInverseTheme(theme).text};
      color: ${getInverseTheme(theme).text};
    }

    .tts-play-text, .tts-stop-text {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .tts-stop-text {
      display: none;
    }
    .tts-playing {
      background: ${theme.iconSecondary};
      color: ${getInverseTheme(theme).text};
    }

    .tts-playing > .tts-stop-text {
      display: flex;
    }

    .tts-playing > .tts-play-text {
      display: none;
    }

    .document-header-domain {
      margin-right: 10px;
    }
    .document-header-domain span {
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .document-header-domain-favicon {
      min-width: 16px;
      object-fit: cover;
    }

  </style>
  `;
};
