import { ulid } from 'ulid';

import { type AnyDocument, type FullZustandState } from '../../../../types';
import { LogLevel } from '../../../../types/logging';
import { notEmpty } from '../../../../typeValidators';
import makeLogger from '../../../../utils/makeLogger';
// eslint-disable-next-line import/no-cycle
import ttsController from '../../../actions/ttsController.platform';
// eslint-disable-next-line import/no-cycle
import database from '../../../database';
import { CancelStateUpdate, updateState } from '../../../models';
import type { StateUpdateOptions, StateUpdateResult } from '../../../types';
import { setCurrentlyReading } from '../other';
import { updateDocuments } from './update';

const logger = makeLogger(__filename);

export async function deleteDocumentById(
  docId: AnyDocument['id'],
  options: { shouldDeleteChildren?: boolean; } & Parameters<typeof updateState>[1],
): StateUpdateResult {
  const doc = await database.collections.documents.findOne(docId);
  if (!doc) {
    logger.warn('document to delete does not exist', { docId });
    return {
      userEvent: undefined,
    };
  }
  return deleteDocument(doc, options);
}

export async function deleteDocumentsById(
  docIds: AnyDocument['id'][],
  options: { shouldDeleteChildren?: boolean; } & StateUpdateOptions,
): StateUpdateResult {
  options.correlationId ??= ulid();
  if (docIds.length === 0) {
    return {};
  }
  const docs = await database.collections.documents.findByIds(docIds);
  if (docs.length !== docIds.length) {
    logger.error('some documents to delete do not exist', { docIds, docs });
  }
  return deleteDocuments(docs, options);
}

export async function deleteDocuments(
  docsToDelete: AnyDocument[],
  options: { shouldDeleteChildren?: boolean; } & StateUpdateOptions,
): StateUpdateResult {
  if (docsToDelete.length === 0) {
    return {};
  }
  options.correlationId ??= ulid();
  const docsToDeleteIds = docsToDelete.map(({ id }) => id);
  const allDocIdsToDelete: string[] = docsToDeleteIds;
  logger.debug('Deleting multiple documents', {
    docsToDeleteIds,
    shouldDeleteChildren: options.shouldDeleteChildren ?? false,
  });
  const docsToDeleteSet = new Set(docsToDeleteIds);
  const docsWithParentToDelete: string[] = [];
  if (options.shouldDeleteChildren) {
    for (const doc of docsToDelete) {
      if (!doc.children) {
        continue;
      }
      allDocIdsToDelete.push(...doc.children);
      docsWithParentToDelete.push(...doc.children);
    }
  }
  const parentDocIds = docsToDelete.map(({ parent }) => parent).filter(notEmpty);
  if (parentDocIds.length > 0) {
    // Ensure we remove these docs from parents first, to avoid data races.
    await updateDocuments(
      parentDocIds,
      (parentDoc) => {
        parentDoc.children = parentDoc.children?.filter((child) => !docsToDeleteSet.has(child));
      },
      {
        logLevelIfNothingFound: LogLevel.Warn,
        ...options,
      },
    );
  }
  const [deleteDocsResult] = await Promise.all([
    // Split up OR branches manually so we can ensure maximum index use
    database.collections.documents.deleteByIds(allDocIdsToDelete, options),
    docsWithParentToDelete.length > 0
      ? database.collections.documents.delete(
        {
          selector: {
            parent: { $in: docsWithParentToDelete },
          },
        },
          options,
        )
      : undefined,
    updateState((state) => {
      if (state.tts?.playingDocId && docsToDeleteSet.has(state.tts?.playingDocId)) {
        stopTextToSpeech();
      }
      let changed = false;
      for (const doc of docsToDelete) {
        changed ||= removeDocumentFromState(doc, state);
      }
      if (!changed) {
        throw new CancelStateUpdate();
      }
    }, options),
  ]);
  return deleteDocsResult;
}

function stopTextToSpeech() {
  setTimeout(() => {
    ttsController.stop();
    setCurrentlyReading(null, { userInteraction: null });
  }, 50);
}

export async function deleteDocument(
  docToDelete: AnyDocument,
  options: { shouldDeleteChildren?: boolean; } & Parameters<typeof updateState>[1],
): StateUpdateResult {
  logger.debug('Deleting document', {
    docToDelete,
    shouldDeleteChildren: options.shouldDeleteChildren ?? false,
  });
  return deleteDocuments([docToDelete], options);
}

/**
 * @return whether state changed at all.
 */
function removeDocumentFromState(doc: AnyDocument, state: FullZustandState) {
  if (!state.transientDocumentsData[doc.id]) {
    return false;
  }
  delete state.transientDocumentsData[doc.id];
  return true;
}
