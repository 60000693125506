import { isHTMLElement } from '../../../typeValidators';
import exceptionHandler from '../../../utils/exceptionHandler.platform';
import makeLogger from '../../../utils/makeLogger';
import type { RangyClassApplier, RangyDomPosition, RangyRange, RangySelection } from '../../types/rangy';
import getClosestHTMLElement from '../getClosestHTMLElement';
import rangy from '../rangy';
// eslint-disable-next-line import/no-cycle
import * as locationSerializer from './base';
import { parseSerializedPosition, unparseSerializedPosition } from './utils';

const logger = makeLogger(__filename);

/**
The objective of this util file is to process 'canonical' positions/ranges/selections from the outside world.
To do this, it converts 'canonical' positions to 'chunk-aware' positions and passes them to our existing locationSerializer, which only understands the latter.
This ensures no code changes to the existing locationSerializer are necessary, and allows outside code to function as though chunk containers do not exist.

A 'chunk-aware' serialized position includes the chunk container index and the node index within that chunk container.
A 'canonical' serialized position only has a simulated "top level node index" which ignores chunk containers altogether.
It represents a position as if chunk containers simply do not exist.

Here is a simplified example. In the implementation, whitespace text nodes are considered children as well.
<html>
<body>
    <div class="rw-chunk-container" data-chunk-child-node-count="2">
        <p>One</p>
        <p>Two</p>
    </div>
    <div class="rw-chunk-container" data-chunk-child-node-count="3">
        <p>Three</p>
        <p>Four</p>
        <p>Five</p>
    </div>
</body>
</html>

The 'chunk-aware' serialized position for "Two" is '1/0:0', because the <p>'s index within the chunk is 1 and the chunk index is 0.
The 'canonical' serialized position for "Two" is '1:0', because the <p>'s index within the entire document (ignoring chunks) is 1.

The 'chunk-aware' serialized position for "Five" is '2/1', because the <p>'s index within the chunk is 2 and the chunk index is 1.
The 'canonical' serialized position for "Five" is '4:0', because the <p>'s index within the entire document (ignoring chunks) is 4 (the previous chunk has 2 children).
*/

class ChunkedLocationError extends Error {
  // exists only for inspection inside debugger
  extra?: { [key: string]: unknown; };

  constructor(message?: string, extra?: { [key: string]: unknown; }) {
    super(message);
    this.extra = extra;
  }
}

type ChunkContainerElement = HTMLDivElement & {
  dataset: {
    chunkId: string;
    chunkIndex: string;
    chunkChildNodeCount: string;
  };
};

export function isChunkedDocumentContentRoot(rootNode: Node): rootNode is Element {
  return isHTMLElement(rootNode) && rootNode.classList.contains('rw-chunk-containers-root');
}

function isChunkContainer(node: Node | null): node is ChunkContainerElement {
  return isHTMLElement(node) && node.classList.contains('rw-chunk-container');
}

function getChunkContainers(contentRoot: Element): ChunkContainerElement[] {
  if (!isChunkedDocumentContentRoot(contentRoot)) {
    throw new ChunkedLocationError('content root is not a chunked document');
  }
  return Array.from(contentRoot.querySelectorAll('div.rw-chunk-container'));
}

function countPrecedingTopLevelNodes(targetContainer: ChunkContainerElement): number {
  if (!isChunkContainer(targetContainer)) {
    throw new ChunkedLocationError('target container is not a chunk container');
  }
  if (!targetContainer.parentElement) {
    throw new ChunkedLocationError('target container has no parent element');
  }
  let precedingTopLevelNodeCount = 0;
  for (const container of getChunkContainers(targetContainer.parentElement)) {
    if (container.isEqualNode(targetContainer)) {
      return precedingTopLevelNodeCount;
    }
    precedingTopLevelNodeCount += Number(container.dataset.chunkChildNodeCount);
  }
  throw new ChunkedLocationError('target chunk container was not found in parent?????');
}

function parseCanonicalSerializedPosition(serialized: string): {
  nodeIndices: number[];
  offset: number;
} {
  let { nodeIndices, offset } = parseSerializedPosition(serialized);
  if (nodeIndices.length === 0) {
    // for ":n" type positions which point to a whole element, we need to treat the 'n' as the top level node index.
    nodeIndices = [offset];
    offset = 0;
  }
  return { nodeIndices, offset };
}

export type ChunkAwarePosition = {
  positionWithinContainer: string;
  container: ChunkContainerElement;
  topLevelNodeIndex: number;
  chunkIndex: number;
  chunkId: string;
  awarePosition: string;
};

export function convertCanonicalPositionToChunkAware(
  canonicalPosition: string,
  contentRoot: Node,
): ChunkAwarePosition | null {
  if (!isChunkedDocumentContentRoot(contentRoot)) {
    return null;
  }
  const { nodeIndices, offset } = parseCanonicalSerializedPosition(canonicalPosition);
  let topLevelNodeIndex = nodeIndices[nodeIndices.length - 1];
  let chunkIndex = 0;
  let targetContainer: ChunkContainerElement | null = null;
  for (const container of getChunkContainers(contentRoot)) {
    const chunkChildNodeCount = Number(container.dataset.chunkChildNodeCount);
    if (topLevelNodeIndex < chunkChildNodeCount) {
      targetContainer = container;
      break;
    }
    topLevelNodeIndex -= chunkChildNodeCount;
    chunkIndex++;
  }
  if (!targetContainer) {
    return null;
  }
  nodeIndices[nodeIndices.length - 1] = topLevelNodeIndex;
  const positionWithinContainer = unparseSerializedPosition(nodeIndices, offset);
  nodeIndices.push(chunkIndex);
  const awarePosition = unparseSerializedPosition(nodeIndices, offset);
  const chunkId = targetContainer.dataset.chunkId;
  return {
    topLevelNodeIndex,
    container: targetContainer,
    chunkIndex,
    chunkId,
    positionWithinContainer,
    awarePosition,
  };
}

export function serializePositionAsCanonical({
  classApplier,
  node: targetNode,
  offset,
  rootNode,
}: {
  classApplier: RangyClassApplier;
  node: Node;
  offset: number;
  rootNode: Node;
}): string {
  if (!isChunkedDocumentContentRoot(rootNode)) {
    return locationSerializer.serializePosition({ classApplier, node: targetNode, offset, rootNode });
  }
  const container = getClosestHTMLElement<ChunkContainerElement>(targetNode, isChunkContainer, rootNode);
  if (!container) {
    exceptionHandler.setExtras({
      targetNode,
      rootNode,
    });
    const stack = new Error().stack;
    throw new ChunkedLocationError('target chunk container is not within root node', { targetNode, rootNode, stack });
  }
  // adjust top level index with 'virtual' top level elements from previous chunks.
  const positionWithinContainer = locationSerializer.serializePosition({
    classApplier,
    node: targetNode,
    rootNode: container,
    offset,
  });
  const { offset: computedOffset, nodeIndices } = parseCanonicalSerializedPosition(positionWithinContainer);
  const precedingTopLevelNodeCount = countPrecedingTopLevelNodes(container);
  nodeIndices[nodeIndices.length - 1] += precedingTopLevelNodeCount;
  const serialized = unparseSerializedPosition(nodeIndices, computedOffset);
  logger.debug('serialize: adjusted serialized pos', {
    serialized,
    positionWithinContainer,
    precedingTopLevelNodeCount,
    nodeIndices,
    computedOffset,
  });
  return serialized;
}

export function deserializeCanonicalPosition({
  classApplier,
  isEndPosition,
  rootNode,
  serialized,
}: {
  classApplier: RangyClassApplier;
  isEndPosition?: boolean;
  rootNode: Node;
  serialized: string;
}): RangyDomPosition {
  if (!isChunkedDocumentContentRoot(rootNode)) {
    return locationSerializer.deserializePosition({
      classApplier,
      isEndPosition,
      rootNode,
      serialized,
    });
  }
  const awarePosition = convertCanonicalPositionToChunkAware(serialized, rootNode);
  if (!awarePosition) {
    exceptionHandler.setExtras({
      rootNode,
      serialized,
    });
    throw new ChunkedLocationError('could not find target chunk container in chunked doc');
  }
  const { container, positionWithinContainer: serializedWithinContainer } = awarePosition;

  if (container.childElementCount === 0) {
    exceptionHandler.captureException('cannot deserialize position pointing into unloaded chunk', {
      extra: {
        awarePosition,
        serialized,
      },
    });
    return {
      node: container,
      offset: 0,
    };
  }
  logger.debug('deserialize: adjusted serialized pos', {
    awarePosition,
    serialized,
  });
  return locationSerializer.deserializePosition({
    classApplier,
    isEndPosition,
    rootNode: container,
    serialized: serializedWithinContainer,
  });
}

export function serializeRangeAsCanonical({
  classApplier,
  containerNode,
  range,
}: {
  classApplier: RangyClassApplier;
  containerNode: Node;
  range: RangyRange;
}): string {
  if (!isChunkedDocumentContentRoot(containerNode)) {
    return locationSerializer.serializeRange({ classApplier, containerNode, range });
  }

  const { endContainer, endOffset, startContainer, startOffset } =
    locationSerializer.extractStartAndEndIfValid(range, containerNode);

  const canonicalPositions = [
    serializePositionAsCanonical({
      rootNode: containerNode,
      classApplier,
      node: startContainer,
      offset: startOffset,
    }),
    serializePositionAsCanonical({
      rootNode: containerNode,
      classApplier,
      node: endContainer,
      offset: endOffset,
    }),
  ];
  return canonicalPositions.join(',');
}

export function deserializeCanonicalRange(
  canonicalSerializedRange: string,
  rootNode: Node,
  doc: Document,
  classApplier: RangyClassApplier,
): RangyRange {
  if (!isChunkedDocumentContentRoot(rootNode)) {
    return locationSerializer.deserializeRange(canonicalSerializedRange, rootNode, doc, classApplier);
  }
  const [start, end] = canonicalSerializedRange
    .split(',')
    .map((canonicalSerializedPos) =>
      convertCanonicalPositionToChunkAware(canonicalSerializedPos, rootNode));
  if (!start || !end) {
    exceptionHandler.setExtras({
      canonicalSerializedRange,
    });
    throw new ChunkedLocationError('could not deserialize start and end');
  }
  const awareSerializedRange = [start, end].map((pos) => pos.awarePosition).join(',');
  return locationSerializer.deserializeRange(awareSerializedRange, rootNode, doc, classApplier);
}

export function deserializeCanonicalSelection(
  serialized: string,
  classApplier: RangyClassApplier,
  rootNode: Node = document.body,
) {
  return locationSerializer.deserializeSelection(
    serialized,
    classApplier,
    rootNode,
    deserializeCanonicalRange,
  );
}

export function serializeSelectionAsCanonical(
  classApplier: RangyClassApplier,
  selection: RangySelection = rangy.getSelection(),
  containerNode: Node = document.body,
) {
  return locationSerializer.serializeSelection(
    classApplier,
    selection,
    containerNode,
    serializeRangeAsCanonical,
  );
}
