// eslint-disable-next-line import/no-cycle
import { LenientReadingPosition } from '../../../types';
import { isMobile } from '../../../utils/environment';
import makeLogger from '../../../utils/makeLogger';
// eslint-disable-next-line import/no-cycle
import { updateState } from '../../models';
import {
  portalGate as portalGateToContentFrame,
} from '../../portalGates/contentFrame/to/reactNativeWebview';

const logger = makeLogger(__filename, { shouldLog: false });
export const addExperiencePoints = async (
  previousPosition: LenientReadingPosition,
  newPosition: LenientReadingPosition,
  correlationId?: string,
): Promise<void> => {

  let amount = 0;
  if (isMobile) {
    amount = await portalGateToContentFrame.methods.mobileGetNumberOfWordsBetweenReadingPositions(
      previousPosition,
      newPosition,
    );
  }
  logger.info(`adding ${amount} of experience`);
  if (amount === 0) {
    return;
  }
  await updateState(
    (state) => {
      if (!state.persistent.experience || state.persistent.experience < 0) {
        state.persistent.experience = 0;
      }
      state.persistent.experience += amount;
      state.persistent.experience = Math.round(state.persistent.experience);
    },
    { userInteraction: 'null', eventName: 'add-experience-points', isUndoable: false, correlationId },
  );
};
