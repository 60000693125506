import includes from 'lodash/includes';
import remove from 'lodash/remove';
import { useCallback } from 'react';

import { MobileHighlightSubpanel, SubMenu } from '../types';
import { isMobile } from '../utils/environment';
import { FeedbackCategory, FeedbackSubCategory } from '../utils/feedback';
// eslint-disable-next-line import/no-cycle
import { CancelStateUpdate, globalState, updateState } from './models';

export const useMobileSubmenuOpen = (submenu?: SubMenu) => {
  return globalState(
    useCallback(
      (state) => {
        if (!submenu) {
          return state.cmdPalette.openSubmenus.length > 0;
        }
        return includes(state.cmdPalette.openSubmenus, submenu);
      },
      [submenu],
    ),
  );
};

const subMenusToConfirmBeforeClosing = [SubMenu.FeatureRequest, SubMenu.BugReport, SubMenu.ParseError];

const subMenuDisplayName = {
  [SubMenu.FeatureRequest]: 'feedback',
  [SubMenu.BugReport]: 'bug report',
  [SubMenu.ParseError]: 'parse error',
};

function hasConfirmationToClose(subMenu: SubMenu) {
  if (isMobile) {
    return true;
  }

  const input = document.querySelector('#cp-input') as HTMLInputElement | HTMLTextAreaElement | null;
  const currentValue = input?.value;

  if (!currentValue) {
    return true;
  }

  const shouldConfirmBeforeClosing = subMenusToConfirmBeforeClosing.includes(subMenu);

  if (shouldConfirmBeforeClosing) {
    // eslint-disable-next-line no-alert
    const isOkToClose = confirm(
      `Are you sure you want to close the in-app ${subMenuDisplayName[subMenu]} dialog? Anything you've written will be lost.`,
    );
    return isOkToClose;
  }

  return true;
}

export const setCmdPaletteOpen = async (
  open: boolean,
  options: Omit<Parameters<typeof updateState>[1], 'eventName'>,
): Promise<void> => {
  if (globalState.getState().cmdPalette.isOpen === open) {
    return;
  }

  const currentSubmenu = globalState.getState().cmdPalette.subMenu;

  if (!open && !hasConfirmationToClose(currentSubmenu)) {
    return;
  }

  await updateState(
    (state) => {
      state.cmdPalette.isOpen = open;
      if (!open) {
        state.cmdPalette.subMenu = SubMenu.Normal;
        state.cmdPalette.openSubmenus = [];
        state.cmdPalette.subMenuAction = undefined;
      }
    },
    { ...options, eventName: 'cmd-palette-opened' },
  );
};

export const setCmdPaletteSubMenu = async (
  menu: SubMenu,
  options: Omit<Parameters<typeof updateState>[1], 'eventName'>,
): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = menu;
    },
    { ...options, eventName: 'cmd-palette-sub-menu-set' },
  );
};

export const setCmdPaletteSubMenuAction = async (
  subMenuAction: string,
  options: Omit<Parameters<typeof updateState>[1], 'eventName'>,
): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.subMenuAction = subMenuAction;
    },
    { ...options, eventName: 'cmd-palette-sub-menu-action-set' },
  );
};

export const setCmdPaletteSubMenuParent = async (
  parent: SubMenu | undefined,
  options: Omit<Parameters<typeof updateState>[1], 'eventName'>,
): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.subMenuParent = parent;
    },
    { ...options, eventName: 'cmd-palette-sub-menu-parent-set' },
  );
};

export const openSaveDocSubMenu = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.SaveDocFromUrl;
    },
    { eventName: 'cmd-palette-save-doc-sub-menu-opened', userInteraction: 'unknown' },
  );
};

export const openReferFriendSubMenu = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.ReferFriend;
    },
    { eventName: 'cmd-palette-refer-friend-sub-menu-opened', userInteraction: 'unknown' },
  );
};

export const openHighlightGptSubMenu = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.HighlightGptPrompt;
    },
    { eventName: 'cmd-palette-gpt-prompt-sub-menu-opened', userInteraction: 'unknown' },
  );
  await setMobileSubmenuOpen(SubMenu.HighlightGptPrompt, true);
};

export const openCustomGptSubMenu = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.ManualGptPrompt;
    },
    { eventName: 'cmd-palette-manual-gpt-prompt-sub-menu-opened', userInteraction: 'unknown' },
  );
};

export const openDocumentGptSubMenu = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.DocumentGptPrompt;
    },
    { eventName: 'cmd-palette-document-gpt-prompt-sub-menu-opened', userInteraction: 'unknown' },
  );
  await setMobileSubmenuOpen(SubMenu.DocumentGptPrompt, true);
};

export const openInputGptSubMenu = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.InputGptPrompt;
    },
    { eventName: 'cmd-palette-input-gpt-prompt-sub-menu-opened', userInteraction: 'unknown' },
  );
  await setMobileSubmenuOpen(SubMenu.InputGptPrompt, true);
};

export const openFiltersSubMenu = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.FilterAllDocuments;
    },
    { eventName: 'cmd-palette-filters-sub-menu-opened', userInteraction: 'unknown' },
  );
};

export const openCreateFilteredsViewSubMenu = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.CreateFilteredView;
    },
    { eventName: 'cmd-palette-create-filtered-view-sub-menu-opened', userInteraction: 'unknown' },
  );
};

export const openEditViewSubMenu = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.EditView;
    },
    { eventName: 'cmd-palette-edit-current-filtered-view-sub-menu-opened', userInteraction: 'unknown' },
  );
  await setMobileSubmenuOpen(SubMenu.EditView, true);
};

export const openEditViewNameSubMenu = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.EditView;
      state.cmdPalette.subMenuAction = 'edit-view-name';
    },
    {
      eventName: 'cmd-palette-edit-current-filtered-view-name-sub-menu-opened',
      userInteraction: 'unknown',
    },
  );
  await setMobileSubmenuOpen(SubMenu.EditView, true);
};

export const openEditViewQuerySubMenu = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.EditView;
      state.cmdPalette.subMenuAction = 'edit-view-query';
    },
    {
      eventName: 'cmd-palette-edit-current-filtered-view-query-sub-menu-opened',
      userInteraction: 'unknown',
    },
  );
  await setMobileSubmenuOpen(SubMenu.EditView, true);
};

export const openSaveFilterSubMenu = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.SaveFilteredView;
    },
    { eventName: 'cmd-palette-save-current-filter-sub-menu-opened', userInteraction: 'unknown' },
  );
};

export const openViewFiltersSubMenu = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.BrowseFilteredView;
    },
    { eventName: 'cmd-palette-view-filters-sub-menu-opened', userInteraction: 'unknown' },
  );
  await setMobileSubmenuOpen(SubMenu.BrowseFilteredView, true);
};

export const openRemoveFiltersSubMenu = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.RemoveFilteredView;
    },
    { eventName: 'cmd-palette-remove-filters-sub-menu-opened', userInteraction: 'unknown' },
  );
};

export const openSplitBySubMenu = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.SelectSplitBy;
    },
    { eventName: 'cmd-palette-view-split-by-sub-menu-opened', userInteraction: 'unknown' },
  );
  await setMobileSubmenuOpen(SubMenu.SelectSplitBy, true);
};

export const openEditFeedSubMenu = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.EditFeed;
    },
    { eventName: 'cmd-palette-edit-feed-sub-menu-opened', userInteraction: 'unknown' },
  );
  await setMobileSubmenuOpen(SubMenu.EditFeed, true);
};

export const openEditFeedNameSubMenu = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.EditFeed;
      state.cmdPalette.subMenuAction = 'edit-feed-name';
    },
    { eventName: 'cmd-palette-edit-feed-name-sub-menu-opened', userInteraction: 'unknown' },
  );
  await setMobileSubmenuOpen(SubMenu.EditView, true);
};

export const openEditTagSubMenu = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.EditTag;
    },
    { eventName: 'cmd-palette-edit-tag-sub-menu-opened', userInteraction: 'unknown' },
  );
  await setMobileSubmenuOpen(SubMenu.EditTag, true);
};

export const openEditQuerySubMenu = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.EditQuery;
    },
    { eventName: 'cmd-palette-edit-query-sub-menu-opened', userInteraction: 'unknown' },
  );
  await setMobileSubmenuOpen(SubMenu.EditQuery, true);
};

export const openCreateViewFromTagSubMenu = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.CreateViewFromTag;
    },
    { eventName: 'cmd-palette-create-view-from-tag-sub-menu-opened', userInteraction: 'unknown' },
  );
  await setMobileSubmenuOpen(SubMenu.CreateViewFromTag, true);
};

export const openMobileLastAnnotationSubpanelOpened = async (): Promise<void> => {
  const lastAnnotationSubpanelOpened = globalState.getState().client.mobileLastAnnotationSubpanelOpened;
  // Adding this check for a bit, because of this:
  // https://linear.app/readwise/issue/RW-20811/sometimes-mobile-tags-and-doc-bottom-sheet-doesnt-open
  const panelToOpen =
    lastAnnotationSubpanelOpened === SubMenu.DocNote || lastAnnotationSubpanelOpened === SubMenu.Tags
      ? lastAnnotationSubpanelOpened
      : SubMenu.DocNote;

  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = panelToOpen;
    },
    { eventName: 'cmd-palette-annotation-sub-menu-opened', userInteraction: 'unknown' },
  );
  await setMobileSubmenuOpen(panelToOpen, true);
};

export const openTagsSubMenu = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.Tags;
    },
    { eventName: 'cmd-palette-tags-sub-menu-opened', userInteraction: 'unknown' },
  );
  await setMobileSubmenuOpen(SubMenu.Tags, true);
};

export const openDocNoteSubMenu = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.DocNote;
    },
    { eventName: 'cmd-palette-doc-note-sub-menu-opened', userInteraction: 'unknown' },
  );
  await setMobileSubmenuOpen(SubMenu.DocNote, true);
};

export const openHighlightTagSubMenu = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.HighlightTags;
    },
    { eventName: 'cmd-palette-highlight-tag-sub-menu-opened', userInteraction: 'unknown' },
  );
  await setMobileSubmenuOpen(SubMenu.HighlightTags, true);
};

export const openHighlightNoteSubMenu = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.HighlightNote;
    },
    { eventName: 'cmd-palette-highlight-note-sub-menu-opened', userInteraction: 'unknown' },
  );
  await setMobileSubmenuOpen(SubMenu.HighlightNote, true);
};

export const openHighlightSubMenu = async (highlightSubmenu?: MobileHighlightSubpanel) => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = highlightSubmenu ?? SubMenu.HighlightNote;
    },
    { eventName: 'cmd-palette-highlight-sub-menu-opened', userInteraction: 'unknown' },
  );
  await setMobileSubmenuOpen(highlightSubmenu ?? SubMenu.HighlightNote, true);
};

export const openBulkActionsSubMenu = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.BulkActions;
    },
    { eventName: 'cmd-palette-view-bulk-actions-sub-menu-opened', userInteraction: 'unknown' },
  );
  await setMobileSubmenuOpen(SubMenu.BulkActions, true);
};

export const openTagsBulkActionsSubMenu = async (
  type: Extract<
    SubMenu,
    SubMenu.BulkActionsTagsAll | SubMenu.BulkActionsTagsAbove | SubMenu.BulkActionsTagsBelow
  >,
): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = type;
    },
    { eventName: 'cmd-palette-tag-bulk-actions-sub-menu-opened', userInteraction: 'unknown' },
  );
};

export const openFeedsSubMenu = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.Feeds;
    },
    { eventName: 'cmd-palette-feeds-sub-menu-opened', userInteraction: 'unknown' },
  );
};

export const openDocumentActionsSubMenu = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.DocumentActions;
    },
    { eventName: 'cmd-palette-document-actions-sub-menu-opened', userInteraction: 'unknown' },
  );
  await setMobileSubmenuOpen(SubMenu.DocumentActions, true);
};

export const openAllCommandsSubMenu = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.AllCommands;
    },
    { eventName: 'cmd-palette-all-commands-sub-menu-opened', userInteraction: 'unknown' },
  );
  await setMobileSubmenuOpen(SubMenu.AllCommands, true);
};

export const openDocumentInboxActionsSubMenu = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.DocumentInboxActions;
    },
    { eventName: 'cmd-palette-document-inbox-actions-sub-menu-opened', userInteraction: 'unknown' },
  );
  await setMobileSubmenuOpen(SubMenu.DocumentInboxActions, true);
};

export const openSearchSubMenu = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.Search;
    },
    { eventName: 'cmd-palette-search-sub-menu-opened', userInteraction: 'unknown' },
  );
  await setMobileSubmenuOpen(SubMenu.Search, true);
};

export const openFeedbackReportSubMenu = async (
  parent: SubMenu,
  category?: FeedbackCategory,
  subCategory?: FeedbackSubCategory,
): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.FeedbackReport;
      state.cmdPalette.subMenuParent = parent;
      state.cmdPalette.feedbackCategory = category;
      state.cmdPalette.feedbackSubCategory = subCategory;
    },
    { eventName: 'cmd-palette-feedback-report-sub-menu-opened', userInteraction: 'unknown' },
  );
  await setMobileSubmenuOpen(SubMenu.FeedbackReport, true);
};

export const openLinkActionsSubMenu = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.LinkActions;
    },
    { eventName: 'cmd-palette-link-actions-sub-menu-opened', userInteraction: 'unknown' },
  );
  await setMobileSubmenuOpen(SubMenu.LinkActions, true);
};

export const openReportingSubMenu = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.ParseError;
      state.cmdPalette.feedbackCategory = FeedbackCategory.Parsing;
      state.cmdPalette.feedbackSubCategory = undefined;
    },
    { eventName: 'cmd-palette-reporting-sub-menu-opened', userInteraction: 'unknown' },
  );
  await setMobileSubmenuOpen(SubMenu.FeedbackReport, true);
};

export const openAccountIssueSubMenu = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.AccountIssue;
      state.cmdPalette.feedbackCategory = FeedbackCategory.Account;
      state.cmdPalette.feedbackSubCategory = undefined;
    },
    { eventName: 'cmd-palette-account-issue-sub-menu-opened', userInteraction: 'unknown' },
  );
  await setMobileSubmenuOpen(SubMenu.FeedbackReport, true);
};

export const openProductQuestionSubMenu = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.ProductQuestion;
      state.cmdPalette.feedbackCategory = FeedbackCategory.Product;
      state.cmdPalette.feedbackSubCategory = undefined;
    },
    { eventName: 'cmd-palette-product-question-sub-menu-opened', userInteraction: 'unknown' },
  );
  await setMobileSubmenuOpen(SubMenu.FeedbackReport, true);
};

export const openThemeSubMenu = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.Theme;
    },
    { eventName: 'cmd-palette-theme-sub-menu-opened', userInteraction: 'unknown' },
  );
  await setMobileSubmenuOpen(SubMenu.Theme, true);
};

export const openFeatureRequestSubMenu = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.FeatureRequest;
    },
    { eventName: 'cmd-palette-feature-request-sub-menu-opened', userInteraction: 'unknown' },
  );
  await setMobileSubmenuOpen(SubMenu.FeatureRequest, true);
};

export const openBugReportSubMenu = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.BugReport;
    },
    { eventName: 'cmd-palette-bug-report-sub-menu-opened', userInteraction: 'unknown' },
  );
  await setMobileSubmenuOpen(SubMenu.BugReport, true);
};

export const openFeedbackSubMenu = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.Feedback;
    },
    { eventName: 'cmd-palette-feedback-sub-menu-opened', userInteraction: 'unknown' },
  );
  await setMobileSubmenuOpen(SubMenu.Feedback, true);
};

export const openFontSettingsSubMenu = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.FontSettings;
    },
    { eventName: 'cmd-palette-font-settings-sub-menu-opened', userInteraction: 'unknown' },
  );
  await setMobileSubmenuOpen(SubMenu.FontSettings, true);
};

export const openStyleSettingsSubMenu = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.StyleSettings;
    },
    { eventName: 'cmd-palette-style-settings-sub-menu-opened', userInteraction: 'unknown' },
  );
  await setMobileSubmenuOpen(SubMenu.StyleSettings, true);
};

export const openExtraStyleSettingsSubMenu = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.ExtraStyleSettings;
    },
    { eventName: 'cmd-palette-extra-style-settings-sub-menu-opened', userInteraction: 'unknown' },
  );
  await setMobileSubmenuOpen(SubMenu.ExtraStyleSettings, true);
};

export const openPaginationDefaultsSubMenu = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.PaginationDefaults;
    },
    { eventName: 'cmd-palette-pagination-defaults-sub-menu-opened', userInteraction: 'unknown' },
  );
  await setMobileSubmenuOpen(SubMenu.PaginationDefaults, true);
};

export const openShortcutsSubmenu = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.Shortcuts;
    },
    { eventName: 'cmd-palette-shortcuts-sub-menu-opened', userInteraction: 'unknown' },
  );
};

export const openTTSVoicesSubmenu = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.TtsVoices;
    },
    { eventName: 'cmd-tts-voices-sub-menu-opened', userInteraction: 'unknown' },
  );
  await setMobileSubmenuOpen(SubMenu.TtsVoices, true);
};

export const openTTSRatesSubmenu = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.TtsSpeechRates;
    },
    { eventName: 'cmd-tts-rates-sub-menu-opened', userInteraction: 'unknown' },
  );
  await setMobileSubmenuOpen(SubMenu.TtsSpeechRates, true);
};

export const openSortListSubmenu = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.SortList;
    },
    { eventName: 'cmd-palette-sortlist-sub-menu-opened', userInteraction: 'unknown' },
  );
  await setMobileSubmenuOpen(SubMenu.SortList, true);
};

export const openSortViewsSubmenu = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.SortViews;
    },
    { eventName: 'cmd-palette-sort-views-sub-menu-opened', userInteraction: 'unknown' },
  );
  await setMobileSubmenuOpen(SubMenu.SortViews, true);
};

export const openSortTagsSubmenu = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.SortTags;
    },
    { eventName: 'cmd-palette-sort-tags-sub-menu-opened', userInteraction: 'unknown' },
  );
  await setMobileSubmenuOpen(SubMenu.SortTags, true);
};

export const openSortFeedsSubmenu = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.SortFeeds;
    },
    { eventName: 'cmd-palette-sort-feeds-sub-menu-opened', userInteraction: 'unknown' },
  );
  await setMobileSubmenuOpen(SubMenu.SortFeeds, true);
};

export const toggleSortListSubmenu = async (): Promise<void> => {
  await updateState(
    (state) => {
      const newIsOpenValue = !includes(state.cmdPalette.openSubmenus, SubMenu.SortList);
      state.cmdPalette.isOpen = newIsOpenValue;

      if (newIsOpenValue) {
        state.cmdPalette.subMenu = SubMenu.SortList;
        state.cmdPalette.openSubmenus.pop();
        state.cmdPalette.openSubmenus.push(SubMenu.SortList);
      } else {
        remove(state.cmdPalette.openSubmenus, (item) => item === SubMenu.SortList);
      }
    },
    { eventName: 'cmd-palette-sortlist-sub-menu-opened', userInteraction: 'unknown' },
  );
};

export const openSubscribeToFeedSubmenu = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.SubscribeToFeed;
    },
    { eventName: 'cmd-palette-subscribe-to-feed-sub-menu-opened', userInteraction: 'unknown' },
  );
  await setMobileSubmenuOpen(SubMenu.SubscribeToFeed, true);
};

export const openAddViewSubmenu = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.FilterQuery;
    },
    { eventName: 'cmd-palette-add-view-sub-menu-opened', userInteraction: 'unknown' },
  );
  await setMobileSubmenuOpen(SubMenu.FilterQuery, true);
};

export const openEditUnsavedQuerySubmenu = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.EditUnsavedQuery;
    },
    { eventName: 'cmd-palette-edit-unsaved-query-sub-menu-opened', userInteraction: 'unknown' },
  );
  await setMobileSubmenuOpen(SubMenu.EditUnsavedQuery, true);
};

export const openFeedActionsSubmenu = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.FeedActions;
    },
    { eventName: 'cmd-palette-feed-actions-sub-menu-opened', userInteraction: 'unknown' },
  );
  await setMobileSubmenuOpen(SubMenu.FeedActions, true);
};

export const openFeedsActionsSubmenu = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.FeedsActions;
    },
    { eventName: 'cmd-palette-feeds-actions-sub-menu-opened', userInteraction: 'unknown' },
  );
  await setMobileSubmenuOpen(SubMenu.FeedsActions, true);
};

export const openViewActionsSubmenu = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.ViewActions;
    },
    { eventName: 'cmd-palette-view-actions-sub-menu-opened', userInteraction: 'unknown' },
  );
  await setMobileSubmenuOpen(SubMenu.ViewActions, true);
};

export const openTagActionsSubmenu = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.TagActions;
    },
    { eventName: 'cmd-palette-tag-actions-sub-menu-opened', userInteraction: 'unknown' },
  );
  await setMobileSubmenuOpen(SubMenu.TagActions, true);
};

export const openSwipeActionsSubmenu = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.SwipeActions;
    },
    { eventName: 'cmd-palette-swipe-actions-sub-menu-opened', userInteraction: 'unknown' },
  );
  await setMobileSubmenuOpen(SubMenu.SwipeActions, true);
};

export const openSaveViewSubmenu = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.SaveView;
    },
    { eventName: 'cmd-palette-save-view-sub-menu-opened', userInteraction: 'unknown' },
  );
  await setMobileSubmenuOpen(SubMenu.SaveView, true);
};

export const openLoginSheet = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.Login;
    },
    { eventName: 'cmd-palette-login-opened', userInteraction: 'unknown' },
  );
  await setMobileSubmenuOpen(SubMenu.Login, true);
};

export const openSignupSheet = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.Signup;
    },
    { eventName: 'cmd-palette-login-opened', userInteraction: 'unknown' },
  );
  await setMobileSubmenuOpen(SubMenu.Signup, true);
};

export const openImageActionsSheet = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.ImageActions;
    },
    { eventName: 'cmd-palette-image-actions-opened', userInteraction: 'tap' },
  );
  await setMobileSubmenuOpen(SubMenu.ImageActions, true);
};

export const openChangeEmailSheet = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.ChangeEmail;
    },
    { eventName: 'cmd-palette-change-email-opened', userInteraction: 'unknown' },
  );
  await setMobileSubmenuOpen(SubMenu.ChangeEmail, true);
};

export const openChangeImportEmailAddressSheet = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.ChangeImportEmailAddress;
    },
    { eventName: 'cmd-palette-change-import-email-address', userInteraction: 'unknown' },
  );
  await setMobileSubmenuOpen(SubMenu.ChangeImportEmailAddress, true);
};

export const openResetPasswordSheet = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.ResetPassword;
    },
    { eventName: 'cmd-palette-reset-password-opened', userInteraction: 'unknown' },
  );
  await setMobileSubmenuOpen(SubMenu.ResetPassword, true);
};

export const openExportAllHighlightsActionsSheet = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.ExportAllHighlightActions;
    },
    { eventName: 'cmd-palette-save-current-filter-sub-menu-opened', userInteraction: 'unknown' },
  );
  await setMobileSubmenuOpen(SubMenu.ExportAllHighlightActions, true);
};

export const openExperienceLeaderboardSheet = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.ExperienceWin;
    },
    { eventName: 'open-experience-leaderboard-sheet', userInteraction: 'unknown' },
  );
  await setMobileSubmenuOpen(SubMenu.ExperienceWin, true);
};

export const setMobileSubmenuOpen = async (submenu: SubMenu, isOpen: boolean): Promise<void> => {
  if (!isMobile) {
    return;
  }
  await updateState(
    (state) => {
      if (isOpen) {
        state.cmdPalette.isOpen = true;
        state.cmdPalette.subMenu = submenu;
        state.cmdPalette.openSubmenus.pop();
        state.cmdPalette.openSubmenus.push(submenu);
      } else {
        if (!state.cmdPalette.openSubmenus.includes(submenu)) {
          throw new CancelStateUpdate();
        }
        remove(state.cmdPalette.openSubmenus, (item) => item === submenu);
        if (state.cmdPalette.openSubmenus.length === 0) {
          state.cmdPalette.isOpen = false;
        }
      }
    },
    { eventName: 'cmd-palette-mobile-submenu-opened', userInteraction: 'unknown' },
  );
};

export const closeAllMobileSubmenus = async () => {
  if (!isMobile) {
    return;
  }
  await updateState(
    (state) => {
      if (state.cmdPalette.openSubmenus.length === 0) {
        throw new CancelStateUpdate();
      }
      state.cmdPalette.openSubmenus = [];
      state.cmdPalette.isOpen = false;
      state.cmdPalette.subMenu = SubMenu.None;
    },
    { eventName: 'all-mobile-submenus-closed', userInteraction: 'unknown' },
  );
};

// Useful when switching submenus in the same sheet
export const replaceCurrentMobileSubmenuOpen = async (
  currentSubmenu: SubMenu,
  newSubMenu: SubMenu,
): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.openSubmenus = state.cmdPalette.openSubmenus.map((subMenu) => {
        if (subMenu === currentSubmenu) {
          return newSubMenu;
        }

        return subMenu;
      });
    },
    { eventName: 'cmd-palette-mobile-submenu-replaced', userInteraction: 'unknown' },
  );
};

export const openAddFeedActionsSubmenu = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.AddFeedActions;
    },
    { eventName: 'cmd-palette-add-feed-actions-sub-menu-opened', userInteraction: 'unknown' },
  );
  await setMobileSubmenuOpen(SubMenu.AddFeedActions, true);
};

export const openAddFeedFolderSubmenu = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.AddFeedFolder;
    },
    { eventName: 'cmd-palette-add-feed-folder-sub-menu-opened', userInteraction: 'unknown' },
  );
  await setMobileSubmenuOpen(SubMenu.AddFeedFolder, true);
};

export const openManageFeedsInFolderSubmenu = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.ManageFeedsInFolder;
    },
    { eventName: 'cmd-palette-manage-feeds-in-folder-sub-menu-opened', userInteraction: 'unknown' },
  );
  await setMobileSubmenuOpen(SubMenu.ManageFeedsInFolder, true);
};

export const openManageFoldersForFeedSubmenu = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.ManageFoldersForFeed;
    },
    { eventName: 'cmd-palette-manage-folders-for-feed-sub-menu-opened', userInteraction: 'unknown' },
  );
  await setMobileSubmenuOpen(SubMenu.ManageFoldersForFeed, true);
};

export const openAddDocumentSheet = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.cmdPalette.isOpen = true;
      state.cmdPalette.subMenu = SubMenu.AddDocument;
    },
    { eventName: 'cmd-palette-add-document-opened', userInteraction: 'unknown' },
  );
  await setMobileSubmenuOpen(SubMenu.AddDocument, true);
};
